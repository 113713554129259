<script>
/**
 * Summary Component
 */
export default {
  data() {
    return {
      apps: [{
        id: 1,
        name: 'Android & TV',
        url: 'https://play.google.com/store/apps/details?id=co.vrsteps.app',
        newTab: true,
        icon: 'font-size-18 ri-android-fill',
        badge: {
          color: 'badge-soft-success',
          text: 'Available'
        }
      }, {
        id: 2,
        name: 'iOS & TV',
        url: '#',
        newTab: false,
        icon: 'font-size-18 ri-apple-fill',
        badge: {
          color: 'badge-soft-warning',
          text: 'Coming Soon'
        }
      }, {
        id: 3,
        name: 'Web Browser',
        url: 'https://www.vrsteps.co/web/',
        newTab: true,
        icon: 'font-size-18 ri-chrome-fill',
        badge: {
          color: 'badge-soft-success',
          text: 'Available'
        }
      }],
      pedisteps: [{
        id: 1,
        name: 'Android & TV',
        url: 'https://play.google.com/store/apps/details?id=co.vrsteps.go',
        newTab: true,
        icon: 'font-size-18 ri-android-fill',
        badge: {
          color: 'badge-soft-success',
          text: 'Available'
        }
      }, {
        id: 2,
        name: 'iOS & TV',
        url: '#',
        newTab: false,
        icon: 'font-size-18 ri-apple-fill',
        badge: {
          color: 'badge-soft-warning',
          text: 'Coming Soon'
        }
      }],
    }
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {

  },
  computed: {
    statData() {
      return [
        {
          title: 'User type',
          icon: 'ri-briefcase-4-line',
          value: this.$store.state.user.type,
        },
        {
          title: 'Number of Patients',
          icon: 'dripicons-user-group',
          value: `${this.$store.state.monitor.patients.length} / ${this.isAdmin ? '∞' : 5}`,
        },
        {
          title: 'New Messages',
          icon: ' dripicons-mail',
          value: '0',
        }
      ]
    }
  }
}
</script>

<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-2">
              <img src="@/assets/images/logo-sm-light.png" width="64" height="64"
                   alt="VRsteps Family Wellness"/>
            </div>
            <div class="col-10">
              <h2 class="font-size-18">VRsteps Family Wellness</h2>
              <h3 class="font-size-16">Release Aug 2024</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-6" :key="a.id" v-for="a in apps">
              <div class="card p-2 mr-2 mb-0">
                <a :href="a.url" :target="{'_blank': a.newTab}" rel="nofollow noopener">
                  <i :class="a.icon"></i>
                  <p class="font-size-14 mb-0">{{ a.name }}</p>
                  <label :class="['badge',a.badge.color]">{{ a.badge.text }}</label>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-body">
          <div class="row mb-3">
            <div class="col-2">
              <img src="@/assets/images/logo.pedisteps.512.png" width="64" height="64"
                   alt="VRsteps Family Wellness"/>
            </div>
            <div class="col-10">
              <h2 class="font-size-18">Pedisteps by VRsteps</h2>
              <h3 class="font-size-16">Release Nov 2024</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 col-6" :key="a.id" v-for="a in pedisteps">
              <div class="card p-2 mr-2 mb-0">
                <a :href="a.url" :target="{'_blank': a.newTab}" rel="nofollow noopener">
                  <i :class="a.icon"></i>
                  <p class="font-size-14 mb-0">{{ a.name }}</p>
                  <label :class="['badge',a.badge.color]">{{ a.badge.text }}</label>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4" v-for="(data, index) in statData" :key="index">
      <div class="card">
        <div class="card-body">
          <div class="media">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-2">{{ data.title }}</p>
              <h4 class="mb-0 text-capitalize">{{ data.value }}</h4>
            </div>
            <div class="text-primary">
              <i :class="`${data.icon} font-size-24`"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
